import Play from 'components/icons/Play';
import Button from 'components/ui/Button';
import Flex from 'components/ui/Flex';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'theme/config/defaultTheme';
import Image from 'components/Image';

const ThumbnailPlayButtonCss = styled(Flex)<{ color?: string }>`
  ${({ color }) => color && `background-color: ${color}`};
  display: flex;
  min-width: 7rem;
  border-radius: 0.5rem;
  height: 4.68rem;
  width: 4.68rem;
`;

const ThumbnailCss = styled.div`
  position: relative;
  border-bottom: 0.0625rem solid #e1d5cf;
  padding-bottom: 1rem;

  > div {
    background-color: ${({ theme }) => theme.colorTokens.default.black};
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

  }
  :hover > div {
    background-color: ${({ theme }) => theme.colorTokens.purple[700]};
`;

export const ThumbnailPlayButton: React.FC = () => (
  <ThumbnailPlayButtonCss justify="center" align="center" color={COLORS.black}>
    <Play color={COLORS.white} />
  </ThumbnailPlayButtonCss>
);

const VideoThumbnail: React.FC<{ image: { src: string; alt: string }; buttonClick: () => void }> = ({ image, buttonClick }) => (
  <Button p0 onClick={buttonClick}>
    <ThumbnailCss>
      <Image src={image.src} alt={image.alt} />
      <ThumbnailPlayButton />
    </ThumbnailCss>
  </Button>
);

export default VideoThumbnail;
