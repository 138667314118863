import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { OldLinkButton } from '../../../components/OldButton';
import ArrowLeft from '../../../components/icons/ArrowLeft';
import SectionHeader from '../../../components/SectionHeader';
import { InsightTopNavigationBackLinkHeaderCss, InsightTopNavigationTabCss, InsightTopNavigationTabsCss } from './ComponentsCss';

const TABS = [
  { to: '/innsikt', name: 'innsikt forside' },
  { to: '/innsikt/ekspertkommentarer', name: 'ekspertkommentarer' },
  { to: '/innsikt/podkaster', name: 'podkaster' },
  { to: '/innsikt/webinarer', name: 'webinarer' },
];

const customStyles = `
  margin-bottom: 2.5rem;
  padding-bottom: 0;`;

export const InsightTabs: React.FC = () => (
  <SectionHeader customStyles={customStyles}>
    <InsightTopNavigationTabsCss>
      {TABS.map(({ to, name }) => (
        <InsightTopNavigationTabCss key={to}>
          <NavLink className="tabLink" activeClassName="isActive" to={`${to}#feed`} exact>
            {name}
          </NavLink>
        </InsightTopNavigationTabCss>
      ))}
    </InsightTopNavigationTabsCss>
  </SectionHeader>
);

const BackToInsightLink: React.FC = () => (
  <InsightTopNavigationBackLinkHeaderCss>
    <OldLinkButton iconLeft={ArrowLeft} to="/innsikt">
      Innsikt forside
    </OldLinkButton>
  </InsightTopNavigationBackLinkHeaderCss>
);

const InsightTopNavigation: React.FC<{
  showTabs?: boolean;
}> = ({ showTabs = false }) => (
  <>
    {showTabs && <InsightTabs />}
    {!showTabs && <BackToInsightLink />}
  </>
);

export default InsightTopNavigation;
