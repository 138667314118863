import * as React from 'react';

import { Catch } from 'components/error/Catch';
import { InlineError } from 'components/error/InlineError';
import { NoticeFeed } from 'components/NoticeFeed';
import { JussensHelterSerialHeader } from 'components/SerialHeader/JussensHelterSerialHeader';
import MenneskeRettSerialHeader from 'components/SerialHeader/MenneskeRettSerialHeader';
import PodcastFeed from 'components/PodcastFeed';
import ReadMoreLink from 'components/ReadMoreLink';
import { ColumnSection, ColumnSectionHeading } from 'components/ColumnSection/ColumnSection';
import DrommekraftSerialHeader from 'components/SerialHeader/DrommekraftSerialHeader';
import DommerpostLuxemburgSerialHeader from 'components/SerialHeader/DommerpostLuxemburgSerialHeader';
import {
  handleTruncate,
  MAIN_FEED,
  EXPERT_FEED,
  HEROES_FEED,
  HUMANRIGHTS_FEED,
  TAKK_OG_LOV_FEED,
  JUDGE_DREAM_FEED,
  PODCASTS_DATA,
  DOMMERPOST_LUXEMBURG_FEED,
} from '../JuridikaInsightPage';
import { InsightTabs } from './InsightTopNavigation';
import {
  InsightFeedWrapperCss,
  InsightMainFeedMainColumnCss,
  InsightMainFeedSideColumnCss,
  InsightMainFeedWrapperCss,
} from './ComponentsCss';
import LivePodcastPromo from './LivePodcastPromo';

const InsightMainFeed: React.FC = () => {
  const [displayVideo, setDisplayVideo] = React.useState<boolean>(false);

  return (
    <InsightFeedWrapperCss>
      <InsightTabs />
      <InsightMainFeedWrapperCss>
        <InsightMainFeedMainColumnCss>
          <ColumnSection id={MAIN_FEED}>
            <Catch catchId={MAIN_FEED} renderError={(props) => <InlineError {...props} />}>
              <NoticeFeed
                feed={MAIN_FEED}
                initialLoadCount={10}
                displayMoreInterval={10}
                showFirstAsLatestStyle
                displayCategory
                displayImage
                displayPublishedAt={false}
                onTruncate={() => handleTruncate(MAIN_FEED)}
              />
            </Catch>
          </ColumnSection>
        </InsightMainFeedMainColumnCss>
        <InsightMainFeedSideColumnCss>
          <ColumnSection id={EXPERT_FEED} hasTopBorder>
            <ColumnSectionHeading>Siste ekspertkommentarer</ColumnSectionHeading>
            <Catch catchId={EXPERT_FEED} renderError={(props) => <InlineError {...props} />}>
              <NoticeFeed
                feed={EXPERT_FEED}
                initialLoadCount={4}
                displayMoreInterval={2}
                showFirstAsLatestStyle={false}
                displayCategory={false}
                displayDescription={false}
                displayImage={false}
                displayPublishedAt={false}
                hasSmallTitle
                onTruncate={() => handleTruncate(EXPERT_FEED)}
                readMoreLink={<ReadMoreLink to="/innsikt/ekspertkommentarer">Flere ekspertkommentarer</ReadMoreLink>}
              />
            </Catch>
          </ColumnSection>
          <ColumnSection id={DOMMERPOST_LUXEMBURG_FEED}>
            <DommerpostLuxemburgSerialHeader />
            <Catch catchId={DOMMERPOST_LUXEMBURG_FEED} renderError={(props) => <InlineError {...props} />}>
              <NoticeFeed
                feed={DOMMERPOST_LUXEMBURG_FEED}
                initialLoadCount={1}
                displayMoreInterval={2}
                showFirstAsLatestStyle={false}
                displayCategory={false}
                fontSize="1rem"
                displayDescription
                displayImage={false}
                displayPublishedAt={false}
                onTruncate={() => handleTruncate(DOMMERPOST_LUXEMBURG_FEED)}
                readMoreLink={<ReadMoreLink to="/innsikt/serier/dommerpost-luxemburg">Se hele serien</ReadMoreLink>}
                hideReadMoreLinkUntilFirstNotice
              />
            </Catch>
          </ColumnSection>
          <ColumnSection id={JUDGE_DREAM_FEED}>
            <DrommekraftSerialHeader />
            <Catch catchId={JUDGE_DREAM_FEED} renderError={(props) => <InlineError {...props} />}>
              <NoticeFeed
                feed={JUDGE_DREAM_FEED}
                initialLoadCount={1}
                displayMoreInterval={2}
                showFirstAsLatestStyle={false}
                displayCategory={false}
                fontSize="1rem"
                displayDescription
                displayImage={false}
                displayPublishedAt={false}
                onTruncate={() => handleTruncate(JUDGE_DREAM_FEED)}
                readMoreLink={<ReadMoreLink to="/innsikt/serier/dømmekraft-og-drømmekraft">Se hele serien</ReadMoreLink>}
              />
            </Catch>
          </ColumnSection>
          {new Date() < new Date('09/01/2022') ? (
            <LivePodcastPromo displayVideo={displayVideo} setDisplayVideo={(value) => setDisplayVideo(value)} />
          ) : null}
          <ColumnSection id={HUMANRIGHTS_FEED}>
            <MenneskeRettSerialHeader />
            <Catch catchId={HUMANRIGHTS_FEED} renderError={(props) => <InlineError {...props} />}>
              <NoticeFeed
                feed={HUMANRIGHTS_FEED}
                initialLoadCount={1}
                displayMoreInterval={2}
                showFirstAsLatestStyle={false}
                displayCategory={false}
                fontSize="1rem"
                displayDescription
                displayImage={false}
                displayPublishedAt={false}
                onTruncate={() => handleTruncate(HUMANRIGHTS_FEED)}
                readMoreLink={<ReadMoreLink to="/innsikt/serier/rett-skal-være-rett">Se hele serien</ReadMoreLink>}
              />
            </Catch>
          </ColumnSection>
          <ColumnSection id={TAKK_OG_LOV_FEED} hasTopBorder>
            <ColumnSectionHeading>{PODCASTS_DATA[TAKK_OG_LOV_FEED].name}</ColumnSectionHeading>
            <PodcastFeed
              feed={TAKK_OG_LOV_FEED}
              initialLoadCount={1}
              displayMoreInterval={0}
              displayCategory={false}
              displayPublishedAt
            />
          </ColumnSection>
          <ColumnSection id={HEROES_FEED}>
            <JussensHelterSerialHeader />
          </ColumnSection>
        </InsightMainFeedSideColumnCss>
      </InsightMainFeedWrapperCss>
    </InsightFeedWrapperCss>
  );
};

export default InsightMainFeed;
