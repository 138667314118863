import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import { listWithoutStyle } from '../../../styleMixins/listMixins';

export const InsightFeedWrapperCss = styled.div`
  margin: 0 auto;
  padding: 0 1.5rem;
  max-width: 116.5625rem;

  ${createMediaQuery(
    'md',
    css`
      padding: 0 2.5rem 2.5rem;
    `
  )}

  & h1 {
    margin-bottom: 2.5rem;
    color: ${(props) => props.theme.colorTokens.red[600]};
    font-family: ${(props) => props.theme.fontFamilies.primary};
  }

  & .c-notice {
    width: 100%;
  }
`;

export const InsightTopNavigationBackLinkHeaderCss = styled.div`
  margin-bottom: 2.5rem;
  padding-bottom: 0.625rem;
  border-bottom: 0.14rem solid #29282d;

  .c-btn {
    letter-spacing: 0.0125rem;
    text-transform: uppercase;

    svg {
      height: 0.8rem;
    }

    svg * {
      fill: ${({ theme }) => theme.colorTokens.red[600]};
    }
  }
`;

export const InsightTopNavigationTabsCss = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;

  display: inline-block;
  font-family: ${(props) => props.theme.fontFamilies.secondary};

  ${createMediaQuery(
    'sm',
    css`
      display: flex;
      justify-content: center;
    `
  )}
`;

export const InsightTopNavigationTabCss = styled.li`
  margin: 0.6rem 0;

  ${createMediaQuery(
    'sm',
    css`
      margin: 0;

      :not(:last-child) {
        margin-right: 2.5rem;
      }
    `
  )}
  .tabLink {
    display: block;
    padding-bottom: 0.6rem;
    font-weight: 600;
    letter-spacing: 0.015rem;
    text-decoration: none;
    color: ${(props) => props.theme.colorTokens.grey[900]};

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: ${(props) => props.theme.colorTokens.grey[900]};
      text-decoration: none !important;
    }

    &:focus,
    &:hover {
      opacity: 0.85;
    }

    &.isActive {
      padding-bottom: 0.35rem;
      border-bottom: 0.25rem solid;
      border-bottom-color: ${(props) => props.theme.colorTokens.red[600]};
    }
  }
`;

export const HeaderCss = styled.section`
  margin-top: 2.5rem;
  flex-basis: 100%;
  text-align: center;
`;

export const LogoWrapperCss = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 1.94rem; /* (svg height / svg width) * width-value */
  width: 9.5rem;
  height: 0;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const TitleCss = styled.h1`
  margin: 1.25rem 0;
  color: ${(props) => props.theme.colorTokens.red[600]};
  font-family: ${(props) => props.theme.fontFamilies.primary};
  font-size: 4.125rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;

  ${createMediaQuery(
    'xs',
    css`
      font-size: 6.875rem;
    `
  )}

  ${createMediaQuery(
    'sm',
    css`
      font-size: 7.5rem;
    `
  )}
`;

export const PodcastPromoCss = styled.div`
  max-width: 33.4375rem;

  p {
    margin: 1.5rem 0;
    font-size: 1.125rem;
  }
`;

export const PodcastProviderLinksCSS = styled.ul`
  ${listWithoutStyle}

  margin: -0.5rem -1rem;

  display: flex;
  flex-wrap: wrap;

  li {
    margin: 0.5rem 1rem;
  }
`;

export const InsightMainFeedWrapperCss = styled.div`
  ${createMediaQuery(
    'md',
    css`
      display: flex;
      justify-content: space-between;
    `
  )}
`;

export const InsightMainFeedMainColumnCss = styled.section`
  ${createMediaQuery(
    'md',
    css`
      margin-right: 2.5rem;
      flex: 0 1 71.25rem;
      min-width: 40.25rem;
    `
  )}
`;
export const InsightMainFeedSideColumnCss = styled.section`
  ${createMediaQuery(
    'md',
    css`
      flex: 0 1 34.6875rem;
      min-width: 18.75rem;
    `
  )}
`;

export const InsightFooterCss = styled.div`
  padding: 2.5rem 0;
  background: #ffebe4;
`;

export const InsightFooterWrapperCss = styled.div`
  ${createMediaQuery(
    'md',

    css`
      display: flex;
      justify-content: space-between;
    `
  )}
`;

export const InsightFooterColumnCss = styled.div`
  color: ${(props) => props.theme.colorTokens.default.black};
  font-size: 1.125rem;
  letter-spacing: 0.02;

  ${createMediaQuery(
    'md',

    css`
      &:first-child {
        margin-right: 8rem;
        flex: 2;
      }
    `
  )}

  & strong {
    font-weight: 600;
  }
`;
export const InsightFooterDeskMembersCss = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 1rem 0;
`;
