import * as React from 'react';

import WidthConstraints from '../../../components/WidthConstraints';
import { InsightFooterColumnCss, InsightFooterCss, InsightFooterDeskMembersCss, InsightFooterWrapperCss } from './ComponentsCss';

const InsightFooter: React.FC = () => (
  <InsightFooterCss>
    <WidthConstraints defaultResponsiveWidth defaultResponsiveMaxWidth size="default">
      <InsightFooterWrapperCss>
        <InsightFooterColumnCss>
          <p>
            <strong>Juridika Innsikt</strong> er et redaktørstyrt fagmagasin som følger rettskildene og juristhverdagen.
          </p>
          <p>
            Juridika Innsikt publiseres på Juridika, Universitetsforlagets digitale juridiske verktøy og bibliotek. Bak Juridika
            Innsikt står Universitetsforlagets jussredaksjon, utgiver av Norges sterkeste jusslitteraturportefølje.
          </p>
          <p>
            Vi skriver selv ulike typer aktualitets- og magasinsaker rettet mot jurister, og vi får ledende jusseksperter til å
            kommentere viktige dommer og lovendringer. Vår ambisjon er å publisere artikler som går i dybden og holder deg à jour
            på ditt juridiske fagområde, og på den måten bidra til at du finner alt juridisk innhold du trenger på Juridika.
          </p>
          <p>
            Med dine innspill kan vi gjøre Juridika Innsikt enda bedre. Vi blir glade for alle tilbakemeldinger og gode ideer som
            sendes til &nbsp;<a href="mailto:post@juridika.no">post&#8203;@juridika.no</a>.
          </p>
        </InsightFooterColumnCss>
        <InsightFooterColumnCss>
          <InsightFooterDeskMembersCss>
            <li>
              <strong>Forlagssjef:</strong> Sturla Blanck Torkildsen
            </li>
          </InsightFooterDeskMembersCss>
        </InsightFooterColumnCss>
      </InsightFooterWrapperCss>
    </WidthConstraints>
  </InsightFooterCss>
);

export default InsightFooter;
