import * as Bowser from 'bowser';

import { useJuridikaConfig } from 'commonUtils/juridikaConfig';

const IE = 'Internet Explorer';

const parser = () => Bowser.getParser(window.navigator.userAgent);

export const useIsIE = (): boolean => {
  if (useJuridikaConfig().isClient) {
    return parser().getBrowserName() === IE;
  }

  return false;
};
