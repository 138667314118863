import * as React from 'react';

import { useIsIE } from 'util/hooks/useBrowserDetection';
import gqlPodcastFeed from 'util/gqlPodcastFeed';
import PodcastNotice from './PodcastNotice';
import { CollectionItem, CollectionList } from './Collection/Collection';
import { GqlPocastEpisodeEdge } from '../models/gqlPodcast/GqlPodcast';
import PodcastProviderLinks from '../pages/JuridikaInsightPage/components/PodcastProviderLinks';
import PodcastPromo from '../pages/JuridikaInsightPage/components/PodcastPromo';
import { ColumnSectionSpacingWrapper } from './ColumnSection/ColumnSection';

const PodcastFeed: React.FC<{
  feed: string;
  initialLoadCount: number;
  displayPublishedAt: boolean;
  displayDescription?: boolean;
  displayCategory: boolean;
  displayMoreInterval: number;
  actionsHasTopPadding?: boolean;
  episodeFilter?(edge: GqlPocastEpisodeEdge): boolean;
}> = ({
  feed,
  initialLoadCount,
  displayCategory,
  displayPublishedAt,
  displayDescription = true,
  displayMoreInterval,
  episodeFilter,
}) => {
  const { fetchFirstPage } = gqlPodcastFeed(feed, initialLoadCount, displayMoreInterval);
  const isIE = useIsIE();

  return fetchFirstPage
    .map((podcastEpisodes): React.ReactElement | null => {
      const episodes = episodeFilter ? podcastEpisodes.edges.filter(episodeFilter) : podcastEpisodes.edges;

      return (
        <div>
          <CollectionList>
            {(episodes || []).slice(0, initialLoadCount).map((edge) => (
              <CollectionItem key={edge.node.id} hasSeparator={false} padding="small">
                <PodcastNotice
                  feed={feed}
                  isIE={isIE}
                  notice={edge.node}
                  displayCategory={displayCategory}
                  displayPublishedAt={displayPublishedAt}
                  displayDescription={displayDescription}
                />
              </CollectionItem>
            ))}
          </CollectionList>
          <PodcastProviderLinks feed={feed} showReadMoreButton />
        </div>
      );
    })
    .mapLoading((): React.ReactElement | null => null)
    .getOrElseGet(() => (
      <ColumnSectionSpacingWrapper>
        <PodcastPromo feed={feed} />
      </ColumnSectionSpacingWrapper>
    ));
};

export default PodcastFeed;
