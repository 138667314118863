import React from 'react';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';

const Play: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="44" height="41" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.398987 0.473755L43.2758 20.6843L0.398987 40.8948V0.473755Z" fill={color ? color : COLOR_TOKENS.purple[600]} />
  </svg>
);

export default Play;
