import * as React from 'react';

import URI from 'urijs';
import ApplePodcastLogo from '../../../components/icons/ApplePodcastLogo';
import SpotifyLogo from '../../../components/icons/SpotifyLogo';
import ReadMoreButton from '../../../components/ReadMoreButton';
import { StyledExternalLink } from '../../../components/StyledLink/StyledLink';
import { PODCASTS_DATA } from '../JuridikaInsightPage';
import { PodcastProviderLinksCSS } from './ComponentsCss';

const PodcastProviderLinks: React.FC<{ feed: string; showReadMoreButton: boolean }> = ({ feed, showReadMoreButton }) => (
  <PodcastProviderLinksCSS>
    <li>
      <StyledExternalLink href={PODCASTS_DATA[feed].spotifyUrl} leftIcon={<SpotifyLogo />}>
        Hør via Spotify
      </StyledExternalLink>
    </li>
    <li>
      <StyledExternalLink href={PODCASTS_DATA[feed].applePodcastsUrl} leftIcon={<ApplePodcastLogo />}>
        Hør via Apple Podcasts
      </StyledExternalLink>
    </li>
    {showReadMoreButton && (
      <li>
        <ReadMoreButton path={URI('/').segment(['innsikt', 'podkaster']).toString()} txt="Flere episoder" />
      </li>
    )}
  </PodcastProviderLinksCSS>
);

export default PodcastProviderLinks;
