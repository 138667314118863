import * as React from 'react';

import Image from '../../../components/Image';
import { PODCASTS_DATA } from '../JuridikaInsightPage';
import { PodcastPromoCss } from './ComponentsCss';
import PodcastProviderLinks from './PodcastProviderLinks';

const PodcastPromo: React.FC<{ feed: string }> = ({ feed }) => (
  <PodcastPromoCss>
    <Image src={PODCASTS_DATA[feed].image} />
    <p>{PODCASTS_DATA[feed].description}</p>
    <PodcastProviderLinks feed={feed} showReadMoreButton={false} />
  </PodcastPromoCss>
);

export default PodcastPromo;
