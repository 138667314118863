import * as React from 'react';

import WebinarFeedGrid from 'components/Webinar/WebinarFeedGrid';
import { InsightFeedWrapperCss } from './ComponentsCss';
import InsightTopNavigation from './InsightTopNavigation';

const InsightWebinarsFeed: React.FC<{
  feed: string;
  showTabs?: boolean;
}> = ({ feed, showTabs }) => {
  return (
    <InsightFeedWrapperCss>
      <InsightTopNavigation showTabs={showTabs} />
      <h1>Webinarer</h1>
      <div id={feed}>
        <WebinarFeedGrid initialLoadCount={10} />
      </div>
    </InsightFeedWrapperCss>
  );
};

export default InsightWebinarsFeed;
