import * as React from 'react';

import PodcastFeedGrid from '../../../components/PodcastFeedGrid';
import { PODCASTS_DATA } from '../JuridikaInsightPage';
import { InsightFeedWrapperCss } from './ComponentsCss';
import InsightTopNavigation from './InsightTopNavigation';

const InsightPodcastFeed: React.FC<{
  feed: string;
  showTabs?: boolean;
}> = ({ feed, showTabs }) => {
  return (
    <InsightFeedWrapperCss>
      <InsightTopNavigation showTabs={showTabs} />
      <h1>{PODCASTS_DATA[feed].name}</h1>
      <div id={feed}>
        <PodcastFeedGrid
          feed={feed}
          initialLoadCount={20}
          displayMoreInterval={20}
          displayCategory={false}
          displayPublishedAt={false}
          onTruncate={() => {}}
        />
      </div>
    </InsightFeedWrapperCss>
  );
};

export default InsightPodcastFeed;
