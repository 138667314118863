import * as React from 'react';
import * as hast from '@universitetsforlaget/hast';
import { formatDate } from 'util/dateHelpers';
import { noticeHeadersSelector } from 'util/staticContentHelpers';
import { HastSelector, takeFirst, matchAllOf, matchTagName, matchPosition } from 'util/HastSelector';
import { HastConfig, overrideIframeTitle, overrideImg } from 'util/hastConfig';
import DividerDot from 'icons/DividerDot';
import styled, { css } from 'styled-components';
import { GqlPocastEpisode } from '../models/gqlPodcast/GqlPodcast';
import { PODCASTS_DATA } from '../pages/JuridikaInsightPage/JuridikaInsightPage';
import { Hast } from './Hast';

const NoticeCss = styled.div<{ hasSeparator: boolean }>`
  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      padding-bottom: 1.25rem;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.colorTokens.brown[400]};
    `}
`;

const ContentCss = styled.div`
  font-size: 1.125rem;

  .icon-dividerDot {
    margin: 0 0.625rem;
    vertical-align: middle;
  }

  ul {
    padding-left: 1.125rem;
  }

  img {
    max-width: 100%;
    width: 100%;
  }
`;

const TypeCss = styled.div`
  color: ${({ theme }) => theme.colorTokens.purple[500]};
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
`;

const PublishedAtCss = styled.span`
  font-size: 1rem;
  text-transform: uppercase;
`;

const DescriptionCss = styled.div`
  display: inline;

  p:first-child {
    display: inline;
  }
`;

const EmbeddedPlayerCss = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
`;

const IEOverlayCss = styled.div`
  position: absolute;
  height: 6.875rem;
  top: 0;
  right: 0;
  left: 6.875rem;
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colorTokens.grey[900]};

  a,
  a:hover,
  a:visited,
  a:active {
    color: ${({ theme }) => theme.colorTokens.default.white};
  }
`;

const embeddedPlayerSelector = new HastSelector({
  mainPlayer: takeFirst(1, matchAllOf(matchTagName('iframe'), matchPosition(0))),
});

const EmbeddedPlayer: React.FC<{
  allHeaders: hast.HastFragmentNode;
  feed: string;
  isIE: boolean;
  hastConfig: HastConfig;
}> = ({ allHeaders, feed, isIE, hastConfig }) => {
  const embeddedPlayer = embeddedPlayerSelector.select('mainPlayer', allHeaders);

  if (!embeddedPlayer.children || embeddedPlayer.children.length === 0) return null;

  return (
    <EmbeddedPlayerCss>
      {isIE && (
        <IEOverlayCss>
          <div>
            Podcastspilleren støtter dessverre ikke utgåtte nettlesere som Internet Explorer, men du kan også lytte via &nbsp;
            <a href={PODCASTS_DATA[feed].spotifyUrl}>Spotify</a> eller &nbsp;
            <a href={PODCASTS_DATA[feed].applePodcastsUrl}>Apple Podcasts</a>.
          </div>
        </IEOverlayCss>
      )}
      <Hast node={embeddedPlayer} config={hastConfig} />
    </EmbeddedPlayerCss>
  );
};

const PodcastNotice: React.FC<{
  feed: string;
  isIE: boolean;
  notice: GqlPocastEpisode;
  displayCategory: boolean;
  displayDescription?: boolean;
  displayPublishedAt: boolean;
  hasSeparator?: boolean;
}> = ({ feed, isIE, notice, displayCategory, displayDescription = true, displayPublishedAt, hasSeparator = false }) => {
  const {
    firstPublishedAt,
    contentCategory: { name: contentCategoryName },
    content,
  } = notice;

  const allHeadersFragment = noticeHeadersSelector.select('allHeaders', content.hast);
  const hastConfig = new HastConfig([overrideIframeTitle('podcast'), overrideImg({ role: 'presentation', loading: 'lazy' })]);

  return (
    <NoticeCss hasSeparator={hasSeparator}>
      <EmbeddedPlayer isIE={isIE} feed={feed} allHeaders={allHeadersFragment} hastConfig={hastConfig} />
      <ContentCss>
        {displayCategory && <TypeCss>{contentCategoryName}</TypeCss>}
        {displayDescription && (
          <ContentCss>
            {displayPublishedAt && [
              <PublishedAtCss key={firstPublishedAt.toString() || ''}>
                {firstPublishedAt && formatDate(firstPublishedAt)}
              </PublishedAtCss>,
              <DividerDot key={`${firstPublishedAt}-dividerDot`} />,
            ]}
            <DescriptionCss>
              <noticeHeadersSelector.Complement fragment={content.hast} config={hastConfig} />
            </DescriptionCss>
          </ContentCss>
        )}
      </ContentCss>
    </NoticeCss>
  );
};

export default PodcastNotice;
