import * as React from 'react';

import * as GqlResult from 'util/graphql/GqlResult';
import gqlNoticeFeed from 'util/gqlNoticeFeed';
import styled, { css } from 'styled-components';
import { StaticNotice } from '../models/staticContent/StaticNotice';
import { FeedSemanticKey } from '../models/staticContent/Feed';
import { Throw } from './error/Throw';
import Notice from './Notice';
import ReadMoreAndCloseButtons from './ReadMoreAndCloseButtons';
import { GridConfig } from './Grid/gridConfig';
import GridItem from './Grid/GridItem';
import Grid from './Grid/Grid';

const ActionsCss = styled.div<{ hasTopPadding: boolean }>`
  margin-bottom: 3.125rem;
  ${({ hasTopPadding }) =>
    hasTopPadding &&
    css`
      margin-top: 0.625rem;
    `}
`;

export interface StaticNoticeEdge {
  cursor: string;
  node: StaticNotice;
}

interface ExpansionState {
  expanded: boolean;
  displayCount: number;
}

const GRID_MARGIN = { topBottom: 3.125, sides: 1.875 };
const GRID_CONFIG: GridConfig = {
  isListGrid: true,
  numberOfColumns: 1,
  margin: GRID_MARGIN,
  mediaQueries: [
    { breakpoint: 'sm', numberOfColumns: 2, margin: GRID_MARGIN },
    { breakpoint: 'md', numberOfColumns: 3, margin: GRID_MARGIN },
  ],
};

export const NoticeFeedGrid: React.FC<{
  // The semantic key of the feed.
  feed: FeedSemanticKey;
  // How many elements to load from GraphQL.
  initialLoadCount: number;
  // How many elements to display in truncated mode.
  truncatedDisplayCount?: number;
  // How many more elements to load when expanded.
  displayMoreInterval: number;
  // Display images
  displayImage: boolean;
  // Display content category
  displayCategory: boolean;
  // Display publishedAt date
  displayPublishedAt: boolean;
  displayDescription?: boolean;
  // Truncation callback
  onTruncate?: () => void;
  actionsHasTopPadding?: boolean;
}> = ({
  feed,
  initialLoadCount,
  truncatedDisplayCount,
  displayMoreInterval,
  displayCategory,
  displayImage,
  displayPublishedAt,
  displayDescription = true,
  onTruncate,
  actionsHasTopPadding = true,
}) => {
  const [expansionState, setExpansionState] = React.useState<ExpansionState>({
    expanded: false,
    displayCount: 0,
  });

  const nonExpandedDisplayCount = truncatedDisplayCount === undefined ? initialLoadCount : truncatedDisplayCount;
  const displayedCount = expansionState.expanded ? expansionState.displayCount : nonExpandedDisplayCount;

  const handleTruncate = () => {
    setExpansionState({
      expanded: false,
      displayCount: 0,
    });
    if (onTruncate) onTruncate();
  };

  const { fetchFirstPage, fetchNextPage } = gqlNoticeFeed(feed, initialLoadCount, displayMoreInterval);

  return fetchFirstPage
    .map((publishedNotices): React.ReactElement | null => {
      const noticesInCache = publishedNotices.edges.length;
      const canDisplayMore = displayedCount < noticesInCache || publishedNotices.pageInfo.hasNextPage;

      const displayMore = () => {
        const desiredDisplayedCount = displayedCount + displayMoreInterval;

        if (desiredDisplayedCount >= noticesInCache) {
          fetchNextPage(publishedNotices.pageInfo.endCursor);
        }

        setExpansionState({
          expanded: true,
          displayCount: desiredDisplayedCount,
        });
      };

      const [mostRecentNotice, ...notices] = publishedNotices.edges.slice(0, displayedCount);

      return (
        <div>
          <Grid config={GRID_CONFIG}>
            <GridItem
              key={mostRecentNotice.cursor}
              config={{
                ...GRID_CONFIG,
                mediaQueries: [],
              }}
            >
              <Notice
                notice={mostRecentNotice.node}
                isLatest
                displayCategory={displayCategory}
                displayImage={displayImage}
                displayPublishedAt={displayPublishedAt}
                displayDescription={displayDescription}
                shouldFlex
                hasSeparator
              />
            </GridItem>
            {notices.map((edge) => {
              return (
                <GridItem key={edge.cursor} config={GRID_CONFIG}>
                  <Notice
                    notice={edge.node}
                    isLatest={false}
                    displayCategory={displayCategory}
                    displayImage={displayImage}
                    displayPublishedAt={displayPublishedAt}
                    displayDescription={displayDescription}
                    hasSeparator
                    shouldFlex={false}
                  />
                </GridItem>
              );
            })}
          </Grid>
          <ActionsCss hasTopPadding={actionsHasTopPadding}>
            <ReadMoreAndCloseButtons
              onLoadMore={displayMore}
              onClose={handleTruncate}
              displayLoadMoreAction={canDisplayMore}
              displayCloseAction={displayedCount > nonExpandedDisplayCount}
            />
          </ActionsCss>
        </div>
      );
    })
    .mapLoading((): React.ReactElement | null => null)
    .getOrElseGet((notOk) => <Throw error={GqlResult.notOkToJuridikaError(notOk)} />);
};

export default NoticeFeedGrid;
