import styled, { css } from 'styled-components';
import createFontStyles from 'util/createFontStyles';
import createMediaQuery from 'util/createMediaQuery';

import Image from '../Image';

export const WrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => props.theme.colorTokens.peach[300]};

  ${createMediaQuery(
    'sm',
    css`
      flex-direction: row;
    `
  )}
`;

export const TextContainerCss = styled.div`
  h2 {
    color: ${(props) => props.theme.colorTokens.grey[900]};

    ${({ theme }) =>
      createFontStyles({
        family: theme.fontFamilies.primary,
        size: 1.25,
        weight: 500,
        letterSpacing: 0.0125,
      })};
  }

  p {
    color: ${(props) => props.theme.colorTokens.grey[900]};

    ${({ theme }) =>
      createFontStyles({
        family: theme.fontFamilies.secondary,
        size: 1.125,
        letterSpacing: 0.015,
      })};
  }
`;

export const ButtonContainerCss = styled.span`
  width: 12.5rem;

  align-self: center;
  text-align: center;
  margin: 0 0 1rem 0;

  ${createMediaQuery(
    'sm',
    css`
      flex: 1 0 150px;
      text-align: right;
      margin: 0;
    `
  )}
`;

export const ModalContentCss = styled.div`
  padding: 1.5rem 1rem;
  width: 100%;
  max-width: 28.125rem;
  background: ${(props) => props.theme.colorTokens.default.white};
`;

export const ModalHeaderCss = styled.div`
  margin-bottom: 2rem;

  h1 {
    margin: 1rem 0;
    font-size: 1.5rem;
    font-weight: 500;
  }

  h2 {
    margin: 0.5rem 0;
    font-size: 1.125rem;
    font-weight: 600;
  }
`;

export const CloseActionContainerCss = styled.div`
  margin-bottom: 1rem;
  text-align: right;

  button {
    padding-right: 0;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const PromoContainerCss = styled.div`
  display: flex;
  justify-content: center;
`;

export const PromoContentCss = styled.div`
  padding: 1rem;
  width: 100%;
  max-width: 89.875rem;
  display: flex;
  flex-direction: column;

  ${createMediaQuery(
    'sm',
    css`
      padding: 2.5rem;
      flex-direction: row;
      justify-content: center;
    `
  )}
`;

export const ContributorPortraitsCss = styled.div`
  display: none;
  margin-right: 3.5rem;

  ${createMediaQuery(
    'sm',
    css`
      display: flex;
      align-items: center;
    `
  )}

  ${createMediaQuery(
    'lg',
    css`
      margin-right: 8rem;
    `
  )}
`;

export const ContributorCss = styled.div`
  text-align: center;

  &:first-child {
    margin-right: 2.5rem;
  }
`;

export const ContributorNameCss = styled.div`
  color: ${({ theme }) => theme.colorTokens.default.white};

  margin-top: 1rem;

  ${({ theme }) =>
    createFontStyles({
      family: theme.fontFamilies.primary,
      size: 1,
      weight: 500,
      mediaQueries: [
        {
          query: 'lg',
          size: 1.375,
          weight: 400,
        },
      ],
    })}
`;

export const ImageCropperCss = styled.div`
  width: 8rem;
  height: 8rem;
  overflow: hidden;
  border-radius: 50%;
  background: ${({ theme }) => theme.colorTokens.default.white};

  ${createMediaQuery(
    'lg',
    css`
      width: 12.5rem;
      height: 12.5rem;
    `
  )}
`;

export const PortraitCss = styled(Image)`
  display: inline;
  margin: 0 auto;
  margin-left: 0%;
  height: 100%;
  width: auto;
`;

export const SmallHeadingCss = styled.div<{ hideOnSmallScreen?: boolean }>`
  ${(props) => props.hideOnSmallScreen && `display: none;`}
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colorTokens.blueGrey[400]};
  ${createFontStyles({
    size: 1.125,
    weight: 600,
    letterSpacing: 0.0187,
    mediaQueries: [
      {
        query: 'lg',
        size: 1.375,
      },
    ],
  })}

  ${(props) =>
    props.hideOnSmallScreen &&
    createMediaQuery(
      'sm',
      css`
        display: flex;
      `
    )}
`;

export const TitleCss = styled.div`
  margin-bottom: 1rem;

  ${({ theme }) =>
    createFontStyles({
      family: theme.fontFamilies.primary,
      size: 1.625,
      weight: 500,
      mediaQueries: [
        {
          query: 'lg',
          size: 3,
        },
      ],
    })}

  color: ${({ theme }) => theme.colorTokens.aqua[400]};
`;

export const SalesTextCss = styled.p`
  color: ${({ theme }) => theme.colorTokens.default.white};
  margin: 0 0 2rem;
  font-size: 1.125rem;

  em {
    ${createFontStyles({
      weight: 600,
    })}
  }
`;
export const ContributorsCss = styled.p`
  color: ${({ theme }) => theme.colorTokens.default.white};
  margin: 0 0 2rem;
  font-size: 1.125rem;

  strong {
    color: ${({ theme }) => theme.colorTokens.aqua[400]};
  }

  ${createMediaQuery(
    'sm',
    css`
      display: none;
    `
  )}
`;

export const PromoContentTextContainerCss = styled.div`
  text-align: center;

  ${createMediaQuery(
    'sm',
    css`
      text-align: left;
    `
  )}
`;

export const ScheduleCss = styled.p`
  color: ${({ theme }) => theme.colorTokens.default.white};
  margin: 0 0 2rem;

  ${createFontStyles({
    size: 1.25,
    weight: 600,
    mediaQueries: [
      {
        query: 'lg',
        size: 1.35,
      },
    ],
  })}
`;

export const WebinarSignupConfirmationCss = styled.div`
  margin-bottom: 2rem;
  font-size: 1.125rem;
`;

export const ImageCss = styled.img`
  width: 100%;
  filter: brightness(0.7);
`;
