import * as React from 'react';

import { FeedSemanticKey } from '../../../models/staticContent/Feed';
import { Catch } from '../../../components/error/Catch';
import { InlineError } from '../../../components/error/InlineError';
import NoticeFeedGrid from '../../../components/NoticeFeedGrid';
import { handleTruncate } from '../JuridikaInsightPage';
import InsightTopNavigation from './InsightTopNavigation';
import { InsightFeedWrapperCss } from './ComponentsCss';

const FEED_SEMANTIC_KEY_TRANSLATION: { [key: string]: JSX.Element | string } = {
  'innsikt-ekspertkommentarer': <>Ekspert&shy;kommen&shy;tarer</>,
  'innsikt-serie-jussens-helter': 'Jussens helter',
  'innsikt-serie-rett-skal-være-rett': 'Rett skal være rett',
  'innsikt-webinar': 'Webinarer',
  'innsikt-serie-dommekraft-og-drommekraft': 'Dømmekraft og drømmekraft',
  'innsikt-serie-dommerpost-luxemburg': 'Dommerpost Luxemburg',
};

const InsightCategoryFeed: React.FC<{
  feed: FeedSemanticKey;
  showTabs?: boolean;
}> = ({ feed, showTabs }) => {
  return (
    <InsightFeedWrapperCss>
      <InsightTopNavigation showTabs={showTabs} />
      <h1>{FEED_SEMANTIC_KEY_TRANSLATION[feed]}</h1>
      <div id={feed}>
        <Catch catchId={feed} renderError={(props) => <InlineError {...props} />}>
          <NoticeFeedGrid
            feed={feed}
            initialLoadCount={20}
            displayMoreInterval={20}
            displayCategory={false}
            displayImage
            displayPublishedAt={false}
            onTruncate={() => handleTruncate(feed)}
          />
        </Catch>
      </div>
    </InsightFeedWrapperCss>
  );
};

export default InsightCategoryFeed;
