import gql from 'graphql-tag';

import { useApolloQuery } from './hooks/useApolloQuery';
import { GqlConnection, concatGqlConnections } from './graphql/GqlConnection';
import * as GqlResult from './graphql/GqlResult';

import { GqlPocastEpisodeEdge } from '../models/gqlPodcast/GqlPodcast';

export interface GqlFeed {
  podcastEpisodes: GqlConnection<GqlPocastEpisodeEdge>;
}

interface GqlVariables {
  podcastId: string;
  first: number;
  cursor?: string;
}

export const PODCAST_FEED_QUERY = gql`
  query Feed($podcastId: String!, $first: Int!, $cursor: String) {
    podcastEpisodes(podcastId: $podcastId, first: $first, cursor: $cursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          firstPublishedAt
          lastPublishedAt
          contentCategory {
            semanticKey
            name
          }
          content {
            hast
          }
        }
      }
    }
  }
`;

export default (podcastId: string, initialLoadCount: number, displayMoreInterval: number) => {
  const [result] = useApolloQuery<GqlFeed, GqlVariables>(PODCAST_FEED_QUERY, {
    variables: {
      podcastId,
      first: initialLoadCount,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    fetchFirstPage: GqlResult.of(result).flatMapOk<GqlConnection<GqlPocastEpisodeEdge>>((data) => {
      if (!data.podcastEpisodes) {
        return GqlResult.unknownError();
      }

      return GqlResult.ok(data.podcastEpisodes);
    }),
    fetchNextPage: (cursor: string) => {
      result.fetchMore({
        query: PODCAST_FEED_QUERY,
        variables: {
          podcastId,
          first: displayMoreInterval,
          cursor,
        },
        updateQuery: (prev: GqlFeed, { fetchMoreResult }): GqlFeed => {
          if (!fetchMoreResult) return prev;

          return {
            podcastEpisodes: concatGqlConnections(prev.podcastEpisodes, fetchMoreResult.podcastEpisodes),
          };
        },
      });
    },
  };
};
