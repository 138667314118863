import * as React from 'react';

import { useIsIE } from 'util/hooks/useBrowserDetection';
import gqlPodcastFeed from 'util/gqlPodcastFeed';
import styled, { css } from 'styled-components';
import { GridConfig } from './Grid/gridConfig';
import Grid from './Grid/Grid';
import GridItem from './Grid/GridItem';
import PodcastNotice from './PodcastNotice';
import ReadMoreAndCloseButtons from './ReadMoreAndCloseButtons';
import PodcastPromo from '../pages/JuridikaInsightPage/components/PodcastPromo';

interface ExpansionState {
  expanded: boolean;
  displayCount: number;
}

const ActionsCss = styled.div<{ hasTopPadding: boolean }>`
  margin-bottom: 3.125rem;
  ${({ hasTopPadding }) =>
    hasTopPadding &&
    css`
      margin-top: 0.625rem;
    `}
`;

const GRID_MARGIN = { topBottom: 3.125, sides: 1.875 };
const GRID_CONFIG: GridConfig = {
  isListGrid: true,
  numberOfColumns: 1,
  margin: GRID_MARGIN,
  mediaQueries: [
    { breakpoint: 'sm', numberOfColumns: 2, margin: GRID_MARGIN },
    { breakpoint: 'md', numberOfColumns: 3, margin: GRID_MARGIN },
  ],
};

const PodcastFeedGrid: React.FC<{
  feed: string;
  initialLoadCount: number;
  displayMoreInterval: number;
  displayPublishedAt: boolean;
  displayDescription?: boolean;
  displayCategory: boolean;
  truncatedDisplayCount?: number;
  onTruncate?: () => void;
  actionsHasTopPadding?: boolean;
}> = ({
  feed,
  initialLoadCount,
  displayMoreInterval,
  displayCategory,
  displayPublishedAt,
  truncatedDisplayCount,
  displayDescription = true,
  onTruncate,
  actionsHasTopPadding = true,
}) => {
  const [expansionState, setExpansionState] = React.useState<ExpansionState>({
    expanded: false,
    displayCount: 0,
  });

  const nonExpandedDisplayCount = truncatedDisplayCount === undefined ? initialLoadCount : truncatedDisplayCount;
  const displayedCount = expansionState.expanded ? expansionState.displayCount : nonExpandedDisplayCount;

  const isIE = useIsIE();

  const handleTruncate = () => {
    setExpansionState({
      expanded: false,
      displayCount: 0,
    });
    if (onTruncate) onTruncate();
  };

  const { fetchFirstPage, fetchNextPage } = gqlPodcastFeed(feed, initialLoadCount, displayMoreInterval);

  return fetchFirstPage
    .map((podcastEpisodes): React.ReactElement | null => {
      const noticesInCache = podcastEpisodes.edges.length;
      const canDisplayMore = displayedCount < noticesInCache || podcastEpisodes.pageInfo.hasNextPage;

      const displayMore = () => {
        const desiredDisplayedCount = displayedCount + displayMoreInterval;

        if (desiredDisplayedCount >= noticesInCache) {
          fetchNextPage(podcastEpisodes.pageInfo.endCursor);
        }

        setExpansionState({
          expanded: true,
          displayCount: desiredDisplayedCount,
        });
      };

      return (
        <div>
          <Grid config={GRID_CONFIG}>
            {podcastEpisodes.edges
              .slice(0, displayedCount)
              .sort((a, b) => (a.node.firstPublishedAt > b.node.firstPublishedAt ? -1 : 1))
              .map((edge) => (
                <GridItem key={edge.node.id} config={GRID_CONFIG}>
                  <PodcastNotice
                    feed={feed}
                    isIE={isIE}
                    notice={edge.node}
                    displayCategory={displayCategory}
                    displayPublishedAt={displayPublishedAt}
                    displayDescription={displayDescription}
                    hasSeparator
                  />
                </GridItem>
              ))}
          </Grid>
          <ActionsCss hasTopPadding={actionsHasTopPadding}>
            <ReadMoreAndCloseButtons
              onLoadMore={displayMore}
              onClose={handleTruncate}
              displayLoadMoreAction={canDisplayMore}
              displayCloseAction={displayedCount > nonExpandedDisplayCount}
            />
          </ActionsCss>
        </div>
      );
    })
    .mapLoading((): React.ReactElement | null => null)
    .getOrElseGet(() => <PodcastPromo feed={feed} />);
};

export default PodcastFeedGrid;
