import VideoThumbnail from 'components/blocks/VideoThumbnail/VideoThumbnail';
import { ColumnSection } from 'components/ColumnSection/ColumnSection';
import { IframeModal } from 'components/ui/Modal/VideoModal';
import * as React from 'react';
import styled from 'styled-components';

const LivePodcastCss = styled(ColumnSection)`
  background-color: ${({ theme }) => theme.colorTokens.purpleVibrant[100]};
  padding: 1.25rem 1.25rem 1.1rem;
`;

const TitleCss = styled.h3`
  font-size: 2.25rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  margin: 1rem 0;
`;

const LivePodcastPromo: React.FC<{
  displayVideo: boolean;
  setDisplayVideo: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ displayVideo, setDisplayVideo }) => {
  return (
    <LivePodcastCss>
      <span>LIVEPODKAST</span>
      <TitleCss>Se, hun snakker! Med Kadafi Zaman, om ... ytringsfrihet!</TitleCss>
      <VideoThumbnail
        image={{
          src: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1623316947/thumbnails/livepodkast_kinxc7.png',
          alt: 'miniatyrbilde fra live podkast med Anine Kierulf og Kadafi Zaman',
        }}
        buttonClick={() => setDisplayVideo(true)}
      />

      <IframeModal
        onClose={() => setDisplayVideo(false)}
        isOpen={displayVideo}
        videoUrl="https://player.vimeo.com/video/723710369"
        videoTitle="Livepodcast"
      />
    </LivePodcastCss>
  );
};

export default LivePodcastPromo;
