import * as React from 'react';

import GridItem from 'components/Grid/GridItem';
import Grid from 'components/Grid/Grid';
import { GridConfig } from 'components/Grid/gridConfig';
import Flex from 'components/ui/Flex';
import Heading from 'components/Heading/Heading';
import VideoThumbnail from 'components/blocks/VideoThumbnail/VideoThumbnail';
import { IframeModal } from 'components/ui/Modal/VideoModal';
import { Webinars } from './Webinars';

interface ExpansionState {
  expanded: boolean;
  displayCount: number;
}

const GRID_MARGIN = { topBottom: 3.125, sides: 1.875 };
const GRID_CONFIG: GridConfig = {
  isListGrid: true,
  numberOfColumns: 1,
  margin: GRID_MARGIN,
  mediaQueries: [
    { breakpoint: 'sm', numberOfColumns: 2, margin: GRID_MARGIN },
    { breakpoint: 'md', numberOfColumns: 3, margin: GRID_MARGIN },
  ],
};

const WebinarFeedGrid: React.FC<{
  initialLoadCount: number;
  truncatedDisplayCount?: number;
}> = ({ initialLoadCount, truncatedDisplayCount }) => {
  const [displayVideo, setDisplayVideo] = React.useState({ display: false, url: '' });
  const [expansionState] = React.useState<ExpansionState>({
    expanded: false,
    displayCount: 0,
  });
  const nonExpandedDisplayCount = truncatedDisplayCount === undefined ? initialLoadCount : truncatedDisplayCount;
  const displayedCount = expansionState.expanded ? expansionState.displayCount : nonExpandedDisplayCount;
  return (
    <div>
      <Grid config={GRID_CONFIG}>
        {Webinars.slice(0, displayedCount)
          .sort((a, b) => b.dateFrom.getTime() - a.dateFrom.getTime())
          .map((webinar) => (
            <GridItem key={webinar.title} config={GRID_CONFIG}>
              <Flex column my3>
                <div>
                  <Heading hTag="h2">{webinar.title}</Heading>
                  <p>{webinar.description}</p>
                </div>
                <VideoThumbnail
                  image={{
                    src: webinar.thumbnail || 'https://via.placeholder.com/500x250',
                    alt: webinar.title,
                  }}
                  buttonClick={() =>
                    setDisplayVideo({
                      display: true,
                      url: webinar.videoUrl ? webinar.videoUrl : '',
                    })
                  }
                />

                <IframeModal
                  onClose={() => setDisplayVideo((prev) => ({ ...prev, display: false }))}
                  isOpen={displayVideo.display}
                  videoUrl={displayVideo.url}
                />
              </Flex>
            </GridItem>
          ))}
      </Grid>
    </div>
  );
};

export default WebinarFeedGrid;
