import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useLocation } from 'react-router';

import { INSIGHT_PAGE_DESCRIPTION } from 'util/metaDescription';
import { generatePageTitle } from 'util/pageTitleUtils';
import { useJuridikaConfig } from 'commonUtils/juridikaConfig';
import { FeedSemanticKey } from '../../models/staticContent/Feed';

import JuridikaLogo from '../../components/icons/Logos/JuridikaLogo';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import InsightCategoryFeed from './components/InsightCategoryFeed';
import InsightMainFeed from './components/InsightMainFeed';
import InsightFooter from './components/InsightFooter';

import { Layout } from '../../containers/Layouts/Layout';
import PageviewStatisticsLogger from '../../containers/StatisticsLogger/PageviewStatisticsLogger';
import InsightPodcastFeed from './components/InsightPodcastFeed';

import { AvoidBankruptcyWebinarPromo } from '../../components/Webinar/WebinarPromo';
import { HeaderCss, LogoWrapperCss, TitleCss } from './components/ComponentsCss';
import InsightWebinarsFeed from './components/InsightWebinarsFeeed';

export const MAIN_FEED = 'innsikt-hovedfeed';
export const EXPERT_FEED = 'innsikt-ekspertkommentarer';
export const HEROES_FEED = 'innsikt-serie-jussens-helter';
export const JUDGE_DREAM_FEED = 'innsikt-serie-dommekraft-og-drommekraft';
export const HUMANRIGHTS_FEED = 'innsikt-serie-rett-skal-være-rett';
export const DOMMERPOST_LUXEMBURG_FEED = 'innsikt-serie-dommerpost-luxemburg';
export const TAKK_OG_LOV_FEED = '5e6f6407ce85e8647799ed65';
export const WEBINARS_FEED = 'innsikt-webinarer';

interface PodcastData {
  [key: string]: {
    image: string;
    name: string;
    slug: string;
    description: string;
    spotifyUrl: string;
    applePodcastsUrl: string;
  };
}

export const PODCASTS_DATA: PodcastData = {
  '5e6f6407ce85e8647799ed65': {
    image: 'https://res.cloudinary.com/dylsvi3ok/image/upload/c_scale,w_250/v1628156497/illustrations/takk-og-lov_qdnt0j.png',
    name: 'Takk og lov',
    slug: 'takk-og-lov',
    description:
      'Takk og lov er en ukentlig podcast om dagsaktuelle og mer varig aktuelle problemstillinger fra jussens verden ledet av Anine Kierulf. Takk og lov presenteres av Juristenes Utdanningssenter og Juridika',
    applePodcastsUrl: 'https://podcasts.apple.com/no/podcast/takk-og-lov-med-anine-kierulf/id1503003150?l=nb',
    spotifyUrl: 'https://open.spotify.com/show/1h9V8j0tWcU7ME9099QXKv?si=5xBgz15VRWSaVmwSbu9TnQ',
  },
};

const PageBreadcrumbs: React.FC<{ ref: React.Ref<HTMLUListElement> }> = React.forwardRef(({ ref }) => (
  <Breadcrumbs
    ref={ref}
    path={[
      {
        segment: '/innsikt',
        name: 'Innsikt',
      },
    ]}
  />
));

export const handleTruncate = (feed: FeedSemanticKey): void => {
  const el = document.getElementById(feed);

  if (el) el.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
};

const JuridikaInsightPage: React.FC = () => {
  const pageTitle = 'Innsikt';
  const ref = React.createRef<HTMLUListElement>();
  const { isClient } = useJuridikaConfig();
  const location = useLocation();
  const [showLivechat, setShowLivechat] = React.useState(false);

  if (isClient) {
    React.useLayoutEffect(() => {
      if (window.location.href.includes('#feed') && ref.current) {
        // extract height of navbar in order to keep the breadcrumbs in view while changing tabs
        const navbarHeight = 74;
        const actualHeight = ref.current.offsetTop - navbarHeight;
        window.scrollTo(0, actualHeight);
      }
    });
  }

  React.useEffect(() => {
    const currentPage = location.pathname.split('/').pop();
    switch (currentPage) {
      case 'innsikt':
      case 'ekspertkommentarer':
      case 'podkaster':
      case 'webinarer':
        setShowLivechat(true);
    }
  }, [location.pathname]);

  return (
    <Layout
      toolbarStart={<PageBreadcrumbs ref={ref} />}
      hasBeigeBackground
      webinarPromo={<AvoidBankruptcyWebinarPromo />}
      livechatVisibility={showLivechat ? 'minimized' : 'hidden'}
    >
      <Helmet>
        <title> {generatePageTitle(pageTitle)}</title>
        <meta name="description" content={INSIGHT_PAGE_DESCRIPTION} />
      </Helmet>
      <PageviewStatisticsLogger title={pageTitle} />
      <div>
        <HeaderCss>
          <LogoWrapperCss>
            <JuridikaLogo />
          </LogoWrapperCss>
          <TitleCss>Innsikt</TitleCss>
        </HeaderCss>
        <Switch>
          <Route exact path="/innsikt">
            <InsightMainFeed />
          </Route>
          <Route path="/innsikt/ekspertkommentarer">
            <InsightCategoryFeed feed={EXPERT_FEED} showTabs />
          </Route>
          <Route path="/innsikt/podkaster/takk-og-lov">
            <InsightPodcastFeed feed={TAKK_OG_LOV_FEED} />
          </Route>
          <Route path="/innsikt/podkaster">
            <InsightPodcastFeed feed={TAKK_OG_LOV_FEED} showTabs />
          </Route>
          <Route path="/innsikt/serier/jussens-helter">
            <InsightCategoryFeed feed={HEROES_FEED} />
          </Route>
          <Route path="/innsikt/webinarer">
            <InsightWebinarsFeed feed={WEBINARS_FEED} showTabs />
          </Route>
          <Route path="/innsikt/serier/rett-skal-være-rett">
            <InsightCategoryFeed feed={HUMANRIGHTS_FEED} />
          </Route>
          <Route path="/innsikt/serier/dømmekraft-og-drømmekraft">
            <InsightCategoryFeed feed={JUDGE_DREAM_FEED} />
          </Route>
          <Route path="/innsikt/serier/dommerpost-luxemburg">
            <InsightCategoryFeed feed={DOMMERPOST_LUXEMBURG_FEED} />
          </Route>
        </Switch>
      </div>
      <InsightFooter />
    </Layout>
  );
};

export default JuridikaInsightPage;
