import * as React from 'react';

import { useHistory } from 'react-router-dom';
import { COLOR_TOKENS } from 'theme/config/defaultTheme';
import { DateFormatter } from 'util/dateHelpers';
import { Button } from '../Button/Button';
import { DivContainer } from '../SectionContainer';
import WidthConstraints from '../WidthConstraints';

import {
  PromoContainerCss,
  ContributorPortraitsCss,
  ContributorCss,
  ImageCropperCss,
  PortraitCss,
  ContributorNameCss,
  SmallHeadingCss,
  TitleCss,
  ScheduleCss,
  PromoContentCss,
  SalesTextCss,
  ContributorsCss,
  PromoContentTextContainerCss as TextContainerCss,
} from './WebinarCss';
import { webinarPromo as WebinarPromoData } from './Webinars';
import { WebinarPromo as WebinarPromoType } from './Webinar';

const WebinarPromo: React.FC<{ webinar: WebinarPromoType }> = ({ webinar }) => {
  const history = useHistory();
  const fd = new DateFormatter(webinar.date);

  if (new Date().getTime() > new Date(webinar.date).getTime()) {
    return null;
  }

  const contributorText = (): JSX.Element => (
    <>
      {webinar.contributors.map((contributor, index: number) => {
        const name = <strong>{contributor.name}</strong>;
        return (
          <React.Fragment key={contributor.id}>
            {index === 0 && <>med {name}</>}
            {index !== 0 && index !== webinar.contributors.length - 1 && <>, {name}, </>}
            {index === webinar.contributors.length - 1 && <> og {name}</>}
          </React.Fragment>
        );
      })}
    </>
  );

  return (
    <WidthConstraints fullWidth>
      <DivContainer backgroundColor={COLOR_TOKENS.purple[700]}>
        <PromoContainerCss>
          <PromoContentCss>
            <ContributorPortraitsCss>
              {webinar.contributors.map((contributor) => (
                <ContributorCss key={contributor.id}>
                  <ImageCropperCss>
                    <PortraitCss src={contributor.imgSrc} alt={contributor.name} />
                  </ImageCropperCss>
                  <ContributorNameCss>{contributor.name}</ContributorNameCss>
                </ContributorCss>
              ))}
            </ContributorPortraitsCss>
            <TextContainerCss>
              <SmallHeadingCss>{webinar.free && 'GRATIS '}WEBINAR</SmallHeadingCss>
              <TitleCss>{webinar.title}</TitleCss>
              <SalesTextCss>{webinar.salesText}</SalesTextCss>
              <ContributorsCss>{contributorText()}</ContributorsCss>
              <ScheduleCss>
                {fd.dayAndDate()}, klokken {fd.hhmm()}
              </ScheduleCss>
              <Button
                onClick={() => history.push(webinar.registerUrl)}
                $paddingConfig={{ topBottom: '1rem', sides: '1.5rem' }}
                $color={COLOR_TOKENS.default.black}
                $backgroundColor={COLOR_TOKENS.aqua[400]}
                $rounded
                $fontConfig={{ weight: 600 }}
              >
                Meld deg på i dag!
              </Button>
            </TextContainerCss>
          </PromoContentCss>
        </PromoContainerCss>
      </DivContainer>
    </WidthConstraints>
  );
};

export default WebinarPromo;

export const AvoidBankruptcyWebinarPromo: React.FC = () => <WebinarPromo webinar={WebinarPromoData} />;
