import React from 'react';
import styled, { css } from 'styled-components';

interface SectionHeaderProps {
  centerContent?: boolean;
  customStyles?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const SectionHeaderCss = styled.h2<{
  customStyles?: string;
  centerContent?: boolean;
}>`
  margin: 0;
  padding: 0.625rem 0;
  border-top: 0.125rem solid #29282d;
  border-bottom: 0.0625rem solid #29282d;
  font-family: ${(props) => props.theme.fontFamilies.primary};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  text-transform: uppercase;

  ${(props) => props.customStyles && props.customStyles}
  ${(props) =>
    props.centerContent &&
    css`
      text-align: center;
    `}
`;

const SectionHeader: React.FC<SectionHeaderProps> = ({ centerContent = false, customStyles, children }) => {
  return (
    <SectionHeaderCss customStyles={customStyles} centerContent={centerContent}>
      {children}
    </SectionHeaderCss>
  );
};
export default SectionHeader;
