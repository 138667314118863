import * as React from 'react';

const ApplePodcastLogo: React.FC = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M25.1821 22.2281c-.2446.6213-.809 1.4961-1.6094 2.1123-.4621.3559-1.0138.6961-1.7716.9206-.8078.2394-1.8028.3203-3.0412.3203H7.4366c-1.2383 0-2.2333-.0809-3.0412-.3203-.7578-.2245-1.3094-.5647-1.7716-.9206-.8003-.6163-1.3648-1.491-1.6094-2.1123-.4927-1.2518-.4977-2.6721-.4977-3.5666V7.3381c0-.8945.005-2.3148.4977-3.5666.2446-.6213.809-1.4961 1.6094-2.1123.4621-.3559 1.0138-.6961 1.7716-.9206C5.2032.4992 6.1982.4183 7.4366.4183H18.76c1.2383 0 2.2333.0809 3.0412.3203.7578.2245 1.3094.5648 1.7716.9206.8003.6163 1.3648 1.491 1.6094 2.1123.4927 1.2518.4977 2.6721.4977 3.5666v11.3234c0 .8944-.0051 2.3147-.4978 3.5666z"
        fill="#000"
      />
      <path
        d="M14.9232 14.8815c-.3776-.3984-1.0408-.6538-1.8237-.6538-.783 0-1.4462.2554-1.8238.6538-.197.2079-.3012.421-.334.7246-.0636.5879-.0279 1.0941.041 1.9034.0656.7712.1902 1.7996.3528 2.847.1157.7454.2097 1.1477.2953 1.4358.1388.4669.6575.8752 1.4687.8752.8111 0 1.3298-.4084 1.4686-.8752.0856-.2881.1796-.6904.2953-1.4358.1626-1.0474.2872-2.0758.3528-2.847.0689-.8094.1046-1.3156.041-1.9034-.0328-.3035-.137-.5167-.334-.7246zm-3.8811-3.5473c0 1.1373.922 2.0593 2.0593 2.0593 1.1374 0 2.0594-.922 2.0594-2.0593 0-1.1374-.922-2.0594-2.0594-2.0594-1.1373 0-2.0593.922-2.0593 2.0594zm2.0338-8.12703687C8.2596 3.2209 4.3131 7.1282 4.2535 11.9442c-.0484 3.9013 2.442 7.2385 5.9195 8.4617.0844.0297.1701-.0404.1568-.1289-.0456-.3029-.0886-.6073-.1281-.9066-.014-.1058-.0814-.197-.1791-.2397-2.7486-1.2008-4.6679-3.9574-4.6371-7.1505.0404-4.1904 3.4622-7.6066 7.6526-7.6405 4.2839-.0346 7.78 3.4402 7.78 7.7162 0 3.1611-1.9109 5.8839-4.6381 7.0751-.0977.0427-.1647.1342-.1786.2399-.0395.2992-.0825.6035-.128.9061-.0134.0885.0723.1586.1567.129 3.4443-1.2115 5.9202-4.4968 5.9202-8.35-.0002-4.8879-3.9835-8.8628-8.8744-8.84883687zM12.916 7.2667c2.7303-.1057 4.9851 2.0847 4.9851 4.7919 0 1.3768-.5833 2.6198-1.5156 3.4952-.0791.0742-.1216.1796-.1155.2879.0186.3293.0118.6493-.0101 1.0107-.0059.0963.1021.1578.1818.1035 1.5634-1.0684 2.5917-2.8649 2.5917-4.8973 0-3.3464-2.7874-6.0541-6.1623-5.9233-3.1364.1216-5.6527 2.7075-5.6929 5.8461-.0264 2.0642 1.0082 3.8929 2.5917 4.9747.0795.0543.1872-.0073.1813-.1035-.022-.3616-.0287-.6816-.0102-1.0109.0061-.1082-.0363-.2136-.1152-.2877-.9614-.9026-1.5518-2.1961-1.5142-3.6241.0662-2.513 2.0923-4.5659 4.6044-4.6632z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default ApplePodcastLogo;
