import * as React from 'react';
import styled, { css } from 'styled-components';
import createMediaQuery from 'util/createMediaQuery';
import SerialHeader from './SerialHeader';

const svg = (
  <svg width="166" height="259" viewBox="0 0 166 259" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#w3pfr4goza)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M99.5 259C54.72 135.242 68.386 120.413 69.05 117.277c1.193-5.625 22.002-36.29 31.592-43.603 4.107-5.92 14.932-14.039 15.095-20.115.072-2.684-1.068-5.865-4.6-4.077-3.531 1.788.238-.386-10.129 9.22-9.265 9.173-11.202 14.63-13.863 15.952-2.66 1.32-6.238-2.113-3.436-5.96 2.803-3.847 9.645-15.948 15.076-24.366 5.431-8.418 5.95-10.658 9.075-15.222.228-6.44-2.258-10.023-6.516-5.762-4.26 4.262-.343.772-9.455 13.294-5.66 7.331-12.07 21.466-15.198 23.394-1.194.736-2.412 4.106-7.997.764C68.187 54.06 94.16 1.353 79.535.144c-5.28-3.452-17.08 56.373-21.673 59.027-3.69-.747-5.253-.625-6.212-2.945-.603-4.41-.837-11.774 1.167-21.76 2.004-9.985 3.931-18.785-.154-23.725-4.085-4.94-6.78.445-7.21 5.909-.429 5.463-5.975 33.01-6.558 49.928-.255 7.404-2.72 10.155-9.955 8.076-10.5-5.642-10-18.428-16-26.142-4-4-9.215-3.488-12-2.5-4.5 2.5 8.366 22.509 13 34.5 5.797 15 15 31 23.5 43C37.188 132.943 47.491 167.756 36 259h63.5z"
        fill="#FBD5DA"
      />
      <path
        d="M168.44 304.512c-44.78-123.759-31.054-138.099-30.39-141.235 1.193-5.625 22.002-36.29 31.592-43.603 4.107-5.92 14.932-14.039 15.095-20.115.072-2.684-1.068-5.865-4.6-4.077-3.531 1.788.238-.386-10.129 9.219-9.265 9.174-11.202 14.632-13.863 15.953-2.66 1.321-6.238-2.113-3.436-5.96 2.803-3.848 9.645-15.948 15.076-24.366 5.431-8.418 5.95-10.658 9.075-15.222.228-6.44-2.258-10.023-6.516-5.762-4.259 4.262-.343.772-9.455 13.294-5.659 7.331-12.069 21.466-15.198 23.394-1.194.736-2.412 4.106-7.997.764-.507-6.736 25.466-59.443 10.841-60.652-5.28-3.452-17.079 56.373-21.673 59.027-3.689-.747-5.253-.625-6.212-2.945-.603-4.41-.837-11.774 1.167-21.76 2.004-9.985 3.931-18.785-.154-23.725-4.085-4.94-6.781.445-7.21 5.909-.429 5.463-5.975 33.01-6.558 49.928-.254 7.404-2.719 10.155-9.955 8.076-10.5-5.642-10-18.428-16-26.142-4-4-9.215-3.488-12-2.5-4.5 2.5 8.366 22.509 13 34.5 5.797 15 15 31 23.5 43-.252 9.431 10.491 43.755-1 135h63z"
        fill="#F17583"
      />
    </g>
    <defs>
      <clipPath id="w3pfr4goza">
        <path fill="#fff" d="M0 0h166v259H0z" />
      </clipPath>
    </defs>
  </svg>
);

const WrapperCss = styled.div`
  > div {
    padding-bottom: 0 !important;

    ${createMediaQuery(
      'xs',
      css`
        padding-right: 0;
      `
    )}
  }

  > div svg {
    min-width: 100%;

    ${createMediaQuery(
      'xs',
      css`
        min-width: 6rem;
      `
    )}

    ${createMediaQuery(
      'sm',
      css`
        min-width: 6rem;
      `
    )}
  }

  > div > div {
    padding-bottom: 1.1rem;
  }
`;

const MenneskeRettSerialHeader: React.FC = () => {
  return (
    <WrapperCss>
      <SerialHeader
        contributors={[]}
        slug="rett-skal-være-rett"
        title="Rett skal være rett"
        description="Ikke alt er en menneskerett. Men noen rettigheter er grunnleggende og universelle. Hvilken utfordring møter menneskerettighetene i vår tid? En serie i 12 deler om de viktigste og mest aktuelle menneskerettighetsspørsmålene i Norge og Europa."
        customImage={svg}
      />
    </WrapperCss>
  );
};

export default MenneskeRettSerialHeader;
