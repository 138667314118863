import uuidv4 from 'uuid/v4';
import { Webinar, WebinarPromo } from './Webinar';

const Webinar1: Webinar = {
  title: 'Er du fit for 55?',
  description:
    'Dette må du vite om bærekraftstaksonomien og EUs grønne reguleringsbølge. Med Cecilie Wetlesen Borge  og Tore Mydske',
  signupText: 'Meld deg på gratis webinar om bærekraftstaksonomien og EUs grønne reguleringsbølge',
  dateFrom: new Date('2021-11-18T09:00:00'),
  dateTo: new Date('2021-11-18T10:00:00'),
  videoUrl: 'https://player.vimeo.com/video/647748186',
  thumbnail: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1623316947/thumbnails/WebinarImg3_lykclr.png',
};

const Webinar2: Webinar = {
  title: 'Fem ting du bør vite om EØS-rett',
  description: 'med Finn Arnesen og Hilde K. Ellingsen',
  signupText: '',
  dateFrom: new Date('2021-09-16T09:00:00'),
  dateTo: new Date('2021-09-16T10:00:00'),
  videoUrl: 'https://player.vimeo.com/video/630081594',
  thumbnail: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1623316947/thumbnails/WebinarImg4_dfqi7w.png',
};

const Webinar3: Webinar = {
  title: 'Unngå konkurs - rekonstruer!',
  description:
    'Dette må du vite om rekonstruksjon av virksomheter som alternativ til konkurs. Med Jon Skjørshammer og Robert Jensen',
  signupText: '',
  dateFrom: new Date('2022-02-17T08:00:00'),
  dateTo: new Date('2022-02-17T09:00:00'),
  videoUrl: 'https://player.vimeo.com/video/662211418',
  thumbnail: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1623316947/thumbnails/WebinarImg2_kblpvr.png',
};

export const webinarPromo: WebinarPromo = {
  id: uuidv4(),
  title: 'Unngå konkurs - rekonstruer!',
  free: true,
  salesText: 'Dette må du vite om rekonstruksjon av virksomheter som alternativ til konkurs.',
  date: '2022-02-17T08:00:00',
  registerUrl: '/webinar/unngå-konkurs–rekonstruer/påmelding',
  contributors: [
    {
      id: uuidv4(),
      name: 'Jon Skjørshammer',
      imgSrc: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1631715103/portraits/jon-skjørshammer_aa0ur5.png',
    },
    {
      id: uuidv4(),
      name: 'Robert Jensen',
      imgSrc: 'https://res.cloudinary.com/dylsvi3ok/image/upload/v1631715099/portraits/robert-jensen_dzygbc.png',
    },
  ],
};

export const EUWebinars: Webinar[] = [Webinar1, Webinar2];
export const Webinars: Webinar[] = [Webinar1, Webinar2, Webinar3];
