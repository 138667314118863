import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/config/defaultTheme';
import createMediaQuery from 'util/createMediaQuery';
import Button from '../Button';
import Modal from './Modal';

interface VideoModalProps {
  children?: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onClose(): void;
  className?: string;
}

interface IframeModalProps {
  children?: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onClose(): void;
  videoUrl: string;
  videoTitle?: string;
  className?: string;
}

const ContainerCss = styled.div`
  width: 100%;
  height: 100%;
`;

const ActionsCss = styled.div`
  text-align: right;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1;
`;

const ModalContentCss = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const IframeCss = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;

  ${createMediaQuery(
    'lg',
    css`
      width: 90%;
      height: 90%;
      top: 5%;
      left: 5%;
    `
  )}
`;

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, className = '', children }) => (
  <Modal isOpen={isOpen} className={className} ariaLabelledbyId="video-modal">
    <ContainerCss>
      <ActionsCss>
        <Button onClick={onClose} iconLeft={{ name: 'Close', color: COLORS.white, size: 22 }} />
      </ActionsCss>
      <ModalContentCss>{children}</ModalContentCss>
    </ContainerCss>
  </Modal>
);

export default VideoModal;

export const IframeModalCss = styled(VideoModal)`
  > div {
    width: auto;
    height: auto;
  }
`;

export const IframeModal: React.FC<IframeModalProps> = ({ isOpen, onClose, videoUrl, videoTitle, className = '' }) => {
  return (
    <IframeModalCss onClose={onClose} isOpen={isOpen} className={className}>
      <IframeCss title={videoTitle || videoUrl} src={videoUrl} allow="fullscreen; picture-in-picture" />
    </IframeModalCss>
  );
};
